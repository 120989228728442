import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import NavBar from '../components/blogNav';

import AuthContext from "../context/AuthContext";

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Card as MCard } from '@mui/material';
import Link from '@mui/material/Link';

import { TbPassword, TbUser } from "react-icons/tb";

import { Card, Input, Spacer } from "@nextui-org/react";
import { Loading } from '@nextui-org/react';
import Footer from "../components/footer.js";

const Login = () => {

    const history = useHistory();
    const { loginUser } = useContext(AuthContext);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        setLoading(true);
        loginUser(username, password, setLoading);
    }

    return (
        <>
            <NavBar />
            <div className='mt-5 d-flex flex-column align-items-center justify-content-center'>
                <Card className="w-50 card-width">
                    <Card.Body>
                        <Typography className="mt-3" variant="h2" sx={{textAlign: 'center', fontWeight: 700}}>
                            Welcome
                        </Typography>
                        <MCard sx={{ backgroundColor: '#000', width: "12%", maxWidth: "12%", aspectRatio: "1/1" }}
                            className="d-flex align-self-center align-items-center justify-content-center">
                                <Typography color="#fff" fontWeight={900} fontSize="2.5rem" className="et-signage">
                                    ET
                                </Typography>
                        </MCard>
                        <Spacer y={5} />
                        <div className="container align-self-center" autoComplete="new-password">
                            <Input
                                clearable
                                underlined
                                size="lg"
                                required
                                fullWidth
                                labelPlaceholder="Username"
                                autoComplete="new-password"
                                contentLeft={
                                    <TbUser />
                                }
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <Spacer y={2.5} />
                            <Input.Password
                                underlined
                                fullWidth
                                clearable
                                labelPlaceholder="Password"
                                autoComplete="new-password"
                                contentLeft={
                                    <TbPassword />
                                }
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="container w-75 mt-5 align-self-center d-grid">
                                <Button variant="contained" className="cv-button mt-5"
                                    color="primary" onClick={handleSubmit} disabled={loading}>
                                    {loading ? <Loading /> : "LOG IN"}
                                </Button>
                            </div>
                            <Spacer y={8} />
                            <Typography variant="h5" textAlign='center' className="d-flex justify-content-center logIn-signUp">
                                Don't have an account?&nbsp;&nbsp;&nbsp;
                                <Link underline='none'
                                    component='button' variant="h5" onClick={(e) => history.push('/blogPosts/account/Register')}>
                                    Sign up
                                </Link>
                            </Typography>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <Footer />
        </>
    )
}

export default Login;