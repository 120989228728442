import React, { useEffect, useContext } from "react";
import { Navbar, Link, Text } from "@nextui-org/react";
import $ from 'jquery';

import AuthContext from "../context/AuthContext";

export default function NavBar() {

    const { user, logoutUser } = useContext(AuthContext);

    useEffect(() => {
        const getLocation = window.location.pathname;
        $(document).ready(function () {
            if (getLocation === '/blogPosts') {
                $('#postsL').addClass('active-nav');
                $('#newPostL').removeClass('active-nav');
                $('#newProjectL').removeClass('active-nav');
                $('#newMailL').removeClass('active-nav');
                $('#loginL').removeClass('active-nav');
                $('#registerL').removeClass('active-nav');
                $('#postsLink').addClass('link-active');
                $('#newPostLink').removeClass('link-active');
                $('#newProjectLink').removeClass('link-active');
                $('#newMailLink').removeClass('link-active');
                $('#loginLink').removeClass('link-active');
                $('#registerLink').removeClass('link-active');
            } else if (getLocation === '/blogPosts/new') {
                $('#newPostL').addClass('active-nav');
                $('#postsL').removeClass('active-nav');
                $('#newProjectL').removeClass('active-nav');
                $('#newMailL').removeClass('active-nav');
                $('#loginL').removeClass('active-nav');
                $('#registerL').removeClass('active-nav');
                $('#newPostLink').addClass('link-active');
                $('#newProjectLink').removeClass('link-active');
                $('#newMailLink').removeClass('link-active');
                $('#postsLink').removeClass('link-active');
                $('#loginLink').removeClass('link-active');
                $('#registerLink').removeClass('link-active');
            } else if (getLocation === '/blogPosts/account/Login') {
                $('#loginL').addClass('active-nav');
                $('#postsL').removeClass('active-nav');
                $('#newPostL').removeClass('active-nav');
                $('#newProjectL').removeClass('active-nav');
                $('#newMailL').removeClass('active-nav');
                $('#registerL').removeClass('active-nav');
                $('#loginLink').addClass('link-active');
                $('#registerLink').removeClass('link-active');
                $('#postsLink').removeClass('link-active');
                $('#newPostLink').removeClass('link-active');
                $('#newProjectLink').removeClass('link-active');
                $('#newMailLink').removeClass('link-active');
            } else if (getLocation === '/blogPosts/account/Register') {
                $('#registerL').addClass('active-nav');
                $('#postsL').removeClass('active-nav');
                $('#newPostL').removeClass('active-nav');
                $('#newProjectL').removeClass('active-nav');
                $('#newMailL').removeClass('active-nav');
                $('#loginL').removeClass('active-nav');
                $('#registerLink').addClass('link-active');
                $('#postsLink').removeClass('link-active');
                $('#newPostLink').removeClass('link-active');
                $('#newProjectLink').removeClass('link-active');
                $('#newMailLink').removeClass('link-active');
                $('#loginLink').removeClass('link-active');
            } else if (getLocation === '/blogPosts/project/new') {
                $('#newProjectL').addClass('active-nav');
                $('#newPostL').removeClass('active-nav');
                $('#postsL').removeClass('active-nav');
                $('#newMailL').removeClass('active-nav');
                $('#loginL').removeClass('active-nav');
                $('#registerL').removeClass('active-nav');
                $('#newProjectLink').addClass('link-active');
                $('#newPostLink').removeClass('link-active');
                $('#newMailLink').removeClass('link-active');
                $('#postsLink').removeClass('link-active');
                $('#loginLink').removeClass('link-active');
                $('#registerLink').removeClass('link-active');
            } else if (getLocation === '/blogPosts/mail/new') {
                $('#newMailL').addClass('active-nav');
                $('#newPostL').removeClass('active-nav');
                $('#postsL').removeClass('active-nav');
                $('#newProjectL').removeClass('active-nav');
                $('#loginL').removeClass('active-nav');
                $('#registerL').removeClass('active-nav');
                $('#newMailLink').addClass('link-active');
                $('#newProjectLink').removeClass('link-active');
                $('#newPostLink').removeClass('link-active');
                $('#postsLink').removeClass('link-active');
                $('#loginLink').removeClass('link-active');
                $('#registerLink').removeClass('link-active');
            } else {
                $('#postsL').addClass('active-nav');
                $('#newPostL').removeClass('active-nav');
                $('#newProjectL').removeClass('active-nav');
                $('#newMailL').removeClass('active-nav');
                $('#loginL').removeClass('active-nav');
                $('#registerL').removeClass('active-nav');
                $('#postsLink').addClass('link-active');
                $('#newPostLink').removeClass('link-active');
                $('#newProjectLink').removeClass('link-active');
                $('#newMailLink').removeClass('link-active');
                $('#loginLink').removeClass('link-active');
                $('#registerLink').removeClass('link-active');
            }
        })
    }, []);
  
  return (
      <Navbar variant={'sticky'} id='blog-nav' css={{ zIndex: 2000 }}>
        <Navbar.Brand>
            <Text b color="inherit" css={{ fontSize: '2rem', fontWeight: '900' }}>
                ET
            </Text>
        </Navbar.Brand>
        <Navbar.Content hideIn="xs" variant="underline-rounded">
          <Navbar.Link href="/">Home</Navbar.Link>
          <div className='h-50'>
              <Navbar.Link id='postsLink' href="/blogPosts">Blog</Navbar.Link>
              <div className='d-flex justify-content-center'>
                  <div id='postsL'>
                  </div>
              </div>
          </div>
        
          {user ? (
              <><div className='h-50'>
                <Navbar.Link id='newPostLink' href="/blogPosts/new">New Post</Navbar.Link>
                <div className='d-flex justify-content-center'>
                  <div id='newPostL'>
                  </div>
                </div>
              </div>
              <div className='h-50'>
                <Navbar.Link id='newProjectLink' href="/blogPosts/project/new">Add Project</Navbar.Link>
                <div className='d-flex justify-content-center'>
                  <div id='newProjectL'>
                  </div>
                </div>
              </div>
              <div className='h-50'>
                <Navbar.Link id='newMailLink' href="/blogPosts/mail/new">New Mail</Navbar.Link>
                <div className='d-flex justify-content-center'>
                  <div id='newMailL'>
                  </div>
                </div>
              </div>
              <div className='h-50'>
                <Navbar.Link id='loginLink' onClick={logoutUser}>Logout</Navbar.Link>
                <div className='d-flex justify-content-center'>
                    <div id='loginL'>
                    </div>
                </div>
              </div> 
              </>
          ) : (<></>) }
        </Navbar.Content>
        <Navbar.Toggle showIn={"xs"} aria-label="toggle navigation" />

        <Navbar.Collapse>
            <Navbar.CollapseItem >
                <Link href="/" color="inherit" >Home</Link>
            </Navbar.CollapseItem>
            <Navbar.CollapseItem >
                <Link href="/blogPosts" color="inherit" >Blog</Link>
            </Navbar.CollapseItem>

            {user ? (
                <><Navbar.CollapseItem >
                    <Link href="/blogPosts/new" className="mt-5" color="inherit" >New Post</Link>
                </Navbar.CollapseItem>
                <Navbar.CollapseItem >
                    <Link href="/blogPosts/project/new" color="inherit" >Add Project</Link>
                </Navbar.CollapseItem>
                <Navbar.CollapseItem >
                    <Link href="/blogPosts/mail/new" color="inherit" >New Mail</Link>
                </Navbar.CollapseItem>
                <Navbar.CollapseItem >
                    <Link onClick={logoutUser} color="inherit" >Logout</Link>
                </Navbar.CollapseItem></>
            ) : (<></>)}
            
        </Navbar.Collapse>
      </Navbar>
  )
}
