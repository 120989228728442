import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import NavBar from "../components/blogNav";
import Footer from "../components/footer.js";
import $ from "jquery";
import dayjs from "dayjs";
import { Simplesharer } from "simple-sharer";
import { RWebShare } from "react-web-share";

import useAxios from "../utils/useAxios";
import AuthContext from "../context/AuthContext";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { Container, Row, Col } from "reactstrap";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import { Avatar as NAvatar, Image, Modal, Loading } from "@nextui-org/react";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import { RiLinksLine, RiExternalLinkLine } from "react-icons/ri";

import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import plugins from "suneditor/src/plugins";
import CodeMirror from "codemirror";
import katex from "katex";
import { IconButton } from "@mui/material";

const BlogPost = () => {
  const api = useAxios();
  const { user } = useContext(AuthContext);

  const history = useHistory();

  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [comments, setComments] = useState([]);

  const [author, setAuthor] = useState("");
  const [email, setEmail] = useState("");
  const [body, setBody] = useState("");

  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [commentId, setCommentId] = useState();

  const Window_height = $(window).outerHeight(true);

  const sh = new Simplesharer();
  sh.title = `Check out this latest Blog post from Emmanuel Tobiloba, titled "${data.title}"`; //title for reddit, this is optional
  sh.text = `Check out this latest Blog post from Emmanuel Tobiloba, titled "${data.title}"`; // description for twitter, not more than a hundred characters, optional.
  sh.hashtags = ["dataScience", "learning", "education"]; // a list of hashtags for twitter,also optional

  const closeHandler = () => {
    setVisible(false);
    setVisible2(false);
    setLoading(false);
  };

  const editorChange = (content) => {
    setBody(content);
  };

  const editPost = () => {
    history.push("/blogPosts/new", data);
  };

  const deletePost = () => {
    setVisible(true);
  };

  const deleteComment = (e) => {
    setVisible2(true);
    setCommentId(e.target.id);
  };

  const confirmDelete = () => {
    api
      .delete(
        `${process.env.REACT_APP_API_URL}/blog-posts/${data.slug}/delete`,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setVisible(false);
        history.push("/blogPosts");
        toast.success("Post deleted successfuly");
      })
      .catch((err) => {
        setVisible(false);
        toast.error(err.response.data.toString());
        setLoading(false);
      });
  };

  const confirmCommentDelete = () => {
    api
      .delete(
        `${process.env.REACT_APP_API_URL}/blog-posts/${data.slug}/comment/${commentId}/delete`,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setVisible2(false);
        window.location.reload(true);
        toast.success("Comment deleted successfuly");
      })
      .catch((err) => {
        setVisible2(false);
        toast.error(err.response.data.toString());
        setLoading(false);
      });
  };

  const addComment = (e) => {
    e.preventDefault();
    setLoading(true);

    let form_data = new FormData();

    form_data.append("author", author);
    form_data.append("email", email);
    form_data.append("post", data.id);
    form_data.append("body", body);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/blog-posts/${data.slug}/comment/new`,
        form_data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        window.location.reload(true);
        toast.success("Comment added successfuly");
      })
      .catch((err) => {
        if (err.response.data.hasOwnProperty("detail")) {
          toast.error(err.response.data["detail"].toString());
        }
        if (err.response.data.hasOwnProperty("author")) {
          toast.error(`Name Error: ${err.response.data["author"].toString()}`);
        }
        if (err.response.data.hasOwnProperty("email")) {
          toast.error(`Email Error: ${err.response.data["email"].toString()}`);
        }
        if (err.response.data.hasOwnProperty("body")) {
          toast.error(`Body Error: ${err.response.data["body"].toString()}`);
        }
        setLoading(false);
      });
  };

  const posts = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/blog-posts/${slug}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      }
    );

    const data = await response.json();
    setData(data);
    if (response.status === 200) {
      setStatus(true);
    } else {
      setStatus(false);
    }

    const commentResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/blog-posts/${slug}/comments/all`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      }
    );

    const commentData = await commentResponse.json();

    if (response.status === 200) {
      setComments(commentData);
    }
  };

  useEffect(() => {
    posts();
  }, []);

  return (
    <>
      <NavBar />
      <Modal
        closeButton
        blur
        preventClose
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Typography id="modal-title" sx={{ fontSize: 18 }}>
            Do you want to delete?
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <Typography className="text-center" fontWeight={700} variant="h3">
            {data.title}
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outlined"
            onClick={closeHandler}
            className="contact-button me-2"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={confirmDelete}
            className="cv-button"
            color="primary"
          >
            {loading ? <Loading type="gradient" /> : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        closeButton
        blur
        preventClose
        aria-labelledby="modal-title"
        open={visible2}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Typography id="modal-title" sx={{ fontSize: 18 }}>
            Do you want to delete commnent?
          </Typography>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button
            variant="outlined"
            onClick={closeHandler}
            className="contact-button me-2"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={confirmCommentDelete}
            className="cv-button"
            color="primary"
          >
            {loading ? <Loading type="gradient" /> : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Container className="mt-5">
        {status ? (
          <div>
            <Typography
              variant="h2"
              sx={{ fontWeight: 700, overflowWrap: "break-word" }}
            >
              {data.title}
            </Typography>
            <Divider
              className="my-3"
              sx={{
                opacity: "1!important",
                background: "#000",
                height: "0.2rem!important",
              }}
            />
            <Row className="align-items-center">
              <Col className="align-self-start">
                <Row className="align-items-center ms-1 flex-nowrap">
                  <NAvatar
                    text="ET"
                    textColor="white"
                    css={{ background: "#000" }}
                  />
                  <Typography sx={{ fontWeight: 600 }}>
                    EMMANUEL TOBILOBA
                  </Typography>
                </Row>
              </Col>
              <Col className="align-self-end">
                <Typography className="text-muted" align="right">
                  Published on: {dayjs(data.created_on).format("MMMM DD, YYYY")}
                </Typography>
              </Col>
            </Row>
            <Divider
              className="my-3"
              sx={{
                opacity: "1!important",
                background: "#000",
                height: "0.2rem!important",
              }}
            />

            <Grid container spacing={0} className="my-5">
              <Grid item xs={0} sm={1} md={2}></Grid>
              <Grid item className="" xs={12} sm={10} md={8}>
                <Image
                  loading="lazy"
                  width={"90%"}
                  height={Window_height * (2 / 3)}
                  src={data.backdrop}
                  alt={data.title}
                  objectFit="cover"
                  className="mb-3"
                />
                <div
                  id="post-body"
                  dangerouslySetInnerHTML={{ __html: data.body }}
                />
                <Typography
                  variant="h6"
                  fontWeight={700}
                  className="mt-5 d-flex align-items-center justify-items-center"
                >
                  Share this post:
                  <Stack direction="row" spacing={0} ml={2}>
                    <Tooltip title="Facebook share" arrow>
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      >
                        <FacebookIcon
                          className="post-social-icon__link"
                          onClick={() => sh.facebook()}
                          round
                          size={32}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Twitter share" arrow>
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      >
                        <TwitterIcon
                          className="post-social-icon__link"
                          onClick={() => sh.twitter()}
                          round
                          size={32}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="LInkedin share" arrow>
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      >
                        <LinkedinIcon
                          className="post-social-icon__link"
                          onClick={() => sh.linkedin()}
                          round
                          size={32}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Whatsapp share" arrow>
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      >
                        <WhatsappIcon
                          className="post-social-icon__link"
                          onClick={() => sh.whatsapp()}
                          round
                          size={32}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Copy link" arrow>
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      >
                        <RiLinksLine
                          className="post-social-icon__link"
                          onClick={() => {
                            sh.copy();
                            toast.info("Link copied");
                          }}
                          round
                          size={32}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Others" arrow>
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      >
                        <RWebShare
                          data={{
                            text: sh.text,
                            url: sh.url,
                            title: sh.title,
                          }}
                          className="post-social-icon__link"
                        >
                          <RiExternalLinkLine size={32} />
                        </RWebShare>
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Typography>

                {user ? (
                  <>
                    <Divider
                      className="my-3"
                      sx={{
                        opacity: "0.5!important",
                        background: "#000",
                        height: "0.07rem!important",
                      }}
                    />

                    <Stack className="j-center" direction={"row"} spacing={2}>
                      <Button
                        variant="contained"
                        onClick={editPost}
                        className="cv-button"
                        color="primary"
                      >
                        Edit Post
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={deletePost}
                        className="contact-button"
                      >
                        Delete
                      </Button>
                    </Stack>
                  </>
                ) : (
                  <></>
                )}

                <Typography
                  className="mt-5"
                  variant="h4"
                  sx={{ fontWeight: 600 }}
                >
                  Comments
                </Typography>
                <Divider
                  className="mb-3"
                  sx={{
                    opacity: "0.8!important",
                    background: "#000",
                    height: "0.1rem!important",
                  }}
                />
                <Grid container className="mt-3 mb-5" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      required
                      label="Name"
                      variant="standard"
                      onChange={(e) => setAuthor(e.target.value)}
                      value={author}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="email"
                      required
                      label="Email"
                      variant="standard"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SunEditor
                      onChange={editorChange}
                      setOptions={{
                        mode: "classic",
                        plugins: plugins,
                        value: { body },
                        defaultStyle:
                          "font-family: 'Alegreya Sans', sans-serif; font-size: 20px;",
                        rtl: false,
                        codeMirror: CodeMirror,
                        katex: katex,
                        tabDisable: false,
                        charCounter: true,
                        resizingBar: false,
                        height: "200",
                        resizeEnable: false,
                        maxHeight: `${Window_height}`,
                        videoFileInput: true,
                        placeholder: "Write a comment...",
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          [
                            "paragraphStyle",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                          ],
                          ["subscript", "superscript"],
                          ["lineHeight", "outdent", "indent", "align"],
                          ["link", "math", "codeView"],
                          "removeFormat",
                        ],
                      }}
                    />
                  </Grid>
                  <Stack
                    className="j-center w-100"
                    direction={"row"}
                    spacing={0}
                  >
                    <Button
                      onClick={addComment}
                      variant="contained"
                      className="cv-button mt-3"
                      color="primary"
                    >
                      {loading ? <Loading type="gradient" /> : "Add Comment"}
                    </Button>
                  </Stack>
                </Grid>
                {comments ? (
                  comments.map((comment) => {
                    return (
                      <ListItem alignItems="flex-start" key={comment.id}>
                        <ListItemAvatar>
                          <Avatar
                            alt={comment.author}
                            src="/static/images/avatar/1.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontWeight: 600 }}>
                              {comment.author}
                              <span className="text-muted">
                                &emsp;
                                {dayjs(comment.created_on).format(
                                  "MMM DD, YYYY"
                                )}
                              </span>
                            </Typography>
                          }
                          secondary={
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="h5"
                              color="text.primary"
                              dangerouslySetInnerHTML={{ __html: comment.body }}
                            ></Typography>
                          }
                        />
                        {user ? (
                          <Link
                            id={comment.id}
                            underline="none"
                            component="button"
                            onClick={deleteComment}
                          >
                            Delete
                          </Link>
                        ) : (
                          <></>
                        )}
                      </ListItem>
                    );
                  })
                ) : (
                  <Col></Col>
                )}
              </Grid>
              <Grid item xs={0} sm={1} md={2}></Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <div className="d-flex vh-100 w-100 flex-column align-items-center justify-content-center">
              <Typography
                variant="h1"
                sx={{ zIndex: 90 }}
                className="position-absolute"
              >
                ET
              </Typography>
              <Loading
                type="gradient"
                loadingCss={{ $$loadingSize: "200px", $$loadingBorder: "10px" }}
              />
            </div>
          </div>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default BlogPost;
