import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import dayjs from "dayjs";

import TopNav from "../components/topNav";
import Footer from "../components/footer.js";

import { Container, Row, Col } from "reactstrap";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import { Card, Text, Pagination, Loading } from "@nextui-org/react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { TypeAnimation } from "react-type-animation";
import AOS from "aos";
import { toast } from "react-toastify";

import hero from "../static/images/GMAN.png";
import about from "../static/images/about-hero.jpg";
import {
  TiSocialFacebookCircular,
  TiSocialTwitterCircular,
  TiSocialLinkedinCircular,
  TiSocialInstagramCircular,
} from "react-icons/ti";
import { TbLocationFilled } from "react-icons/tb";
import { MdPhone, MdEmail } from "react-icons/md";

const Home = () => {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [featuredPost, setFeaturedPost] = useState([]);
  const [featuredPostsStatus, setFeaturedPostsStatus] = useState(false);
  const [pages, setPages] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [paginationSize, setPaginationSize] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [projectCount, setProjectCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const windowWidth = $(window).innerWidth();

  const pageChange = (page) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/blog-posts/project/all?page=${page}&page_size=${pageSize}`
        )
        .then((res) => {
          setProjects(res.data.results);
        });
    } catch (error) {
      toast.error("Unable to get projects, Refresh or Try again!");
    }
  };

  const sendMail = async () => {
    setLoading(true);
    const contactData = {
      email: email,
      full_name: name,
      message: message,
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/blog-posts/contact/new`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactData),
      }
    );
    const data = await response.json();

    if (response.status === 201) {
      setLoading(false);
      window.location.reload(true);
      toast.success("Your mail has been sent");
    } else {
      if (data.hasOwnProperty("detail")) {
        toast.error(data["detail"].toString());
      }
      if (data.hasOwnProperty("full_name")) {
        toast.error(`Full name: ${data["full_name"].toString()}`, {
          position: "bottom-right",
        });
      }
      if (data.hasOwnProperty("email")) {
        toast.error(`Email: ${data["email"].toString()}`, {
          position: "bottom-right",
        });
      }
      if (data.hasOwnProperty("message")) {
        toast.error(`Message: ${data["message"].toString()}`, {
          position: "bottom-right",
        });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    AOS.init();
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/blog-posts/featured-post`)
        .then((res) => {
          setFeaturedPost(res.data);
          setFeaturedPostsStatus(true);
        });
    } catch (error) {
      toast.error("Unable to get posts, Refresh or Try again!");
      setFeaturedPostsStatus(false);
    }
    $(document).ready(function () {
      $("table").addClass("MuiTable-root css-18qulp9");

      $(window).resize(function () {
        var windowWidth = $(window).innerWidth();
        try {
          if (windowWidth >= 1 && windowWidth <= 599) {
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/blog-posts/project/all?page=1&page_size=6`
              )
              .then((res) => {
                setProjects(res.data.results);
                setPages(Math.ceil(res.data.count / res.data.results.length));
                setPaginationSize("md");
                setProjectCount(res.data.count);
              });
          } else if (windowWidth >= 600 && windowWidth <= 899) {
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/blog-posts/project/all?page=1&page_size=6`
              )
              .then((res) => {
                setProjects(res.data.results);
                setPages(Math.ceil(res.data.count / res.data.results.length));
                setPaginationSize("lg");
                setProjectCount(res.data.count);
              });
          } else if (windowWidth >= 900) {
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/blog-posts/project/all?page=1&page_size=6`
              )
              .then((res) => {
                setProjects(res.data.results);
                setPages(Math.ceil(res.data.count / res.data.results.length));
                setPaginationSize("xl");
                setProjectCount(res.data.count);
              });
          }
        } catch (error) {
          toast.error("Unable to get projects, Refresh or Try again!");
        }
      });
      try {
        if (windowWidth >= 1 && windowWidth <= 599) {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/blog-posts/project/all?page=1&page_size=6`
            )
            .then((res) => {
              setProjects(res.data.results);
              setPages(Math.ceil(res.data.count / res.data.results.length));
              setPaginationSize("md");
              setProjectCount(res.data.count);
            });
        } else if (windowWidth >= 600 && windowWidth <= 899) {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/blog-posts/project/all?page=1&page_size=6`
            )
            .then((res) => {
              setProjects(res.data.results);
              setPages(Math.ceil(res.data.count / res.data.results.length));
              setPaginationSize("lg");
              setProjectCount(res.data.count);
            });
        } else if (windowWidth >= 900) {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/blog-posts/project/all?page=1&page_size=6`
            )
            .then((res) => {
              setProjects(res.data.results);
              setPages(Math.ceil(res.data.count / res.data.results.length));
              setPaginationSize("xl");
              setProjectCount(res.data.count);
            });
        }
      } catch (error) {
        toast.error("Unable to get projects, Refresh or Try again!");
      }

      $(window)
        .on("scroll", function () {
          var WindowScrollTop = $(window).scrollTop(),
            home_top = $("#home")?.offset().top,
            home_height = $("#home")?.outerHeight(true),
            about_top = $("#about")?.offset().top,
            about_height = $("#about")?.outerHeight(true),
            pro_top = $("#projects")?.offset().top,
            pro_height = $("#projects")?.outerHeight(true),
            cont_top = $("#contact")?.offset().top,
            cont_height = $("#contact")?.outerHeight(true);
          if (
            WindowScrollTop >= about_top - 500 &&
            WindowScrollTop < about_top + about_height - 500
          ) {
            $("#aboutL")?.addClass("active-nav");
            $("#aboutLink")?.addClass("link-active");
            $("#projectsLink")?.removeClass("link-active");
            $("#homeLink")?.removeClass("link-active");
            $("#contactLink")?.removeClass("link-active");
            $("#homeL")?.removeClass("active-nav");
            $("#proL")?.removeClass("active-nav");
            $("#contactL")?.removeClass("active-nav");
          } else if (
            WindowScrollTop >= pro_top - 500 &&
            WindowScrollTop < pro_top + pro_height - 500
          ) {
            $("#proL")?.addClass("active-nav");
            $("#projectsLink")?.addClass("link-active");
            $("#homeLink")?.removeClass("link-active");
            $("#aboutLink")?.removeClass("link-active");
            $("#contactLink")?.removeClass("link-active");
            $("#aboutL")?.removeClass("active-nav");
            $("#homeL")?.removeClass("active-nav");
            $("#contactL")?.removeClass("active-nav");
          } else if (
            WindowScrollTop >= cont_top - 500 &&
            WindowScrollTop < cont_top + cont_height
          ) {
            $("#contactL")?.addClass("active-nav");
            $("#contactLink")?.addClass("link-active");
            $("#projectsLink")?.removeClass("link-active");
            $("#aboutLink")?.removeClass("link-active");
            $("#homeLink")?.removeClass("link-active");
            $("#homeL")?.removeClass("active-nav");
            $("#aboutL")?.removeClass("active-nav");
            $("#proL")?.removeClass("active-nav");
          } else {
            $("#homeL")?.addClass("active-nav");
            $("#homeLink")?.addClass("link-active");
            $("#projectsLink")?.removeClass("link-active");
            $("#aboutLink")?.removeClass("link-active");
            $("#contactLink")?.removeClass("link-active");
            $("#aboutL")?.removeClass("active-nav");
            $("#proL")?.removeClass("active-nav");
            $("#contactL")?.removeClass("active-nav");
          }
        })
        .scroll();
      $("#address-container").on("mouseenter", function () {
        $("#h-address").css("background-color", "#000");
        $("#address-text").css("color", "#fff");
      });
      $("#address-container").on("mouseleave", function () {
        $("#h-address").css("background-color", "#e0e0e0");
        $("#address-text").css("color", "#000");
      });
      $("#mail-container").on("mouseenter", function () {
        $("#h-mail").css("background-color", "#000");
        $("#mail-text").css("color", "#fff");
      });
      $("#mail-container").on("mouseleave", function () {
        $("#h-mail").css("background-color", "#e0e0e0");
        $("#mail-text").css("color", "#000");
      });
      $("#phone-container").on("mouseenter", function () {
        $("#h-phone").css("background-color", "#000");
        $("#phone-text").css("color", "#fff");
      });
      $("#phone-container").on("mouseleave", function () {
        $("#h-phone").css("background-color", "#e0e0e0");
        $("#phone-text").css("color", "#000");
      });
    });
  }, []);

  return (
    <>
      <TopNav />
      {featuredPostsStatus ? (
        <>
          <Container fluid className="px-0">
            <Box id="home" sx={{ bgcolor: "#000", height: "100vh" }}>
              <Row className="f-reverse vh-100 w-100 mx-0">
                <Col xs="12" sm="12" lg="6">
                  <div className="hero-container vh-100">
                    <div className="hero-container-inner">
                      <LazyLoadImage
                        src={hero}
                        effect="blur"
                        alt="Emmanuel Tobiloba"
                        className="hero"
                        data-aos="slide-up"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="false"
                        data-aos-duration="3000"
                        data-aos-offset="500"
                      />
                    </div>
                  </div>
                </Col>
                <Col
                  xs="12"
                  sm="12"
                  lg="6"
                  className="align-self-center px-0 hero-text"
                >
                  <Typography
                    variant="h2"
                    color={"#fff"}
                    sx={{ fontWeight: "800" }}
                  >
                    Hello,
                  </Typography>
                  <Typography variant="h2" color={"#fff"}>
                    <TypeAnimation
                      sequence={[
                        "I am Emmanuel Tobiloba",
                        2000,
                        "I am a Data Analyst",
                        2000,
                      ]}
                      cursor
                      repeat={Infinity}
                    />
                  </Typography>
                  <ul class="social-icon justify-content-start">
                    <li className="social-icon__item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon__link"
                        href="https://www.facebook.com/EmmanuelTobiloba2"
                      >
                        <TiSocialFacebookCircular />
                      </a>
                    </li>
                    <li className="social-icon__item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon__link"
                        href="https://www.twitter.com/Emmantobiloba"
                      >
                        <TiSocialTwitterCircular />
                      </a>
                    </li>
                    <li className="social-icon__item">
                      <a
                        className="social-icon__link"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/emmanuel-tobiloba-058502170"
                      >
                        <TiSocialLinkedinCircular />
                      </a>
                    </li>
                    <li className="social-icon__item">
                      <a
                        className="social-icon__link"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/Emmanuel_great"
                      >
                        <TiSocialInstagramCircular />
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Box>
            <Container id="about" className="py-5 my-5">
              <Row className="f-reverse-2">
                <Col xs="12" sm="12" md="8">
                  <Typography
                    className="text-center"
                    variant="h3"
                    sx={{ fontWeight: "800" }}
                  >
                    About Me.
                  </Typography>
                  <Typography
                    className="text-muted text-center"
                    variant="h6"
                    sx={{ fontWeight: "600", letterSpacing: "0.3rem" }}
                  >
                    DATA ANALYST & STORY TELLER
                  </Typography>
                  <Typography className="mt-3" sx={{ fontSize: "1.3rem" }}>
                    I am a data analyst that is passionate about transforming
                    difficult data into knowledge that can be used to solve
                    problems and expand businesses. I have a demonstrated track
                    record of using data to drive business choices and enhance
                    performance, and I have more than a year of expertise in the
                    field. With a background in statistical analysis, data
                    visualization, and data mining, I am adept in extracting and
                    analyzing data using technologies like SQL, Spreadsheets,
                    PowerBI and Tableau.
                  </Typography>
                  <Typography sx={{ fontSize: "1.3rem" }}>
                    I appreciate working with others to complete projects
                    because I am always looking to increase my knowledge and
                    skills. I enjoy reading and writing when I'm not studying
                    data.
                  </Typography>
                  <Stack
                    className="mt-5 j-center"
                    direction={"row"}
                    spacing={2}
                  >
                    <Button
                      variant="contained"
                      className="cv-button"
                      color="primary"
                    >
                      Download CV
                    </Button>
                    <Button variant="outlined" className="contact-button">
                      Contact me
                    </Button>
                  </Stack>
                </Col>
                <Col xs="0" sm="0" md="4" className="align-self-center r-width">
                  <Box className="bg">
                    <div className="about-container-inner pb-3">
                      <LazyLoadImage
                        effect="blur"
                        src={about}
                        className="about-image"
                        alt="About Emmanuel Tobiloba"
                      />
                    </div>
                  </Box>
                </Col>
              </Row>
            </Container>
            <Container id="projects" className="py-5 my-5">
              <Typography
                className="text-center"
                variant="h3"
                sx={{ fontWeight: "800" }}
              >
                Projects
              </Typography>
              <Grid container spacing={2} className="mt-3">
                {projects.length > 0 ? (
                  projects.map((project) => {
                    return (
                      <Grid item xs={6} sm={4} md={3} key={project.id}>
                        <Card
                          variant="bordered"
                          css={{ w: "100%" }}
                          isHoverable
                          isPressable
                          onClick={(e) =>
                            window.open(project.link, "_blank", "noreferrer")
                          }
                        >
                          <Card.Header
                            css={{ position: "absolute", zIndex: 1, top: 5 }}
                            className="overlay"
                          >
                            <Col>
                              <Text h2 color="black" transform="capitalize">
                                {project.title}
                              </Text>
                            </Col>
                          </Card.Header>
                          <Card.Image
                            loading="lazy"
                            src={project.backdrop}
                            width="100%"
                            objectFit="cover"
                            alt="Card image background"
                            css={{ aspectRatio: 1 / 1 }}
                          />
                        </Card>
                      </Grid>
                    );
                  })
                ) : (
                  <>
                    <Grid item xs={6} sm={4} md={3}>
                      <Card
                        variant="bordered"
                        css={{ w: "100%", aspectRatio: 1 / 1 }}
                        isHoverable
                      >
                        <Skeleton
                          variant="rectangle"
                          width={"100%"}
                          height={"100%"}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                      <Card
                        variant="bordered"
                        css={{ w: "100%", aspectRatio: 1 / 1 }}
                        isHoverable
                      >
                        <Skeleton
                          variant="rectangle"
                          width={"100%"}
                          height={"100%"}
                        />
                      </Card>
                    </Grid>
                    {windowWidth >= 600 && windowWidth <= 899 ? (
                      <Grid item xs={6} sm={4} md={3}>
                        <Card
                          variant="bordered"
                          css={{ w: "100%", aspectRatio: 1 / 1 }}
                          isHoverable
                        >
                          <Skeleton
                            variant="rectangle"
                            width={"100%"}
                            height={"100%"}
                          />
                        </Card>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    {windowWidth > 899 ? (
                      <>
                        <Grid item xs={6} sm={4} md={3}>
                          <Card
                            variant="bordered"
                            css={{ w: "100%", aspectRatio: 1 / 1 }}
                            isHoverable
                          >
                            <Skeleton
                              variant="rectangle"
                              width={"100%"}
                              height={"100%"}
                            />
                          </Card>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                          <Card
                            variant="bordered"
                            css={{ w: "100%", aspectRatio: 1 / 1 }}
                            isHoverable
                          >
                            <Skeleton
                              variant="rectangle"
                              width={"100%"}
                              height={"100%"}
                            />
                          </Card>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Grid>
              {projectCount > pageSize ? (
                <div className="d-flex align-items-center mt-3 mb-5 justify-content-center">
                  <Pagination
                    rounded
                    bordered
                    size={paginationSize}
                    total={pages}
                    initialPage={1}
                    onChange={pageChange}
                  />
                </div>
              ) : (
                <></>
              )}
            </Container>
            <Container id="featured-posts" className="py-5 my-5">
              <Typography
                className="text-center"
                variant="h3"
                sx={{ fontWeight: "800" }}
              >
                Featured posts
              </Typography>
              <Grid container spacing={2} className="mt-3">
                {featuredPostsStatus ? (
                  featuredPost.length > 0 ? (
                    featuredPost.slice(0, 4).map((post) => {
                      return (
                        <Grid item xs={6} md={3} key={post.id}>
                          <Card
                            variant="bordered"
                            css={{ w: "100%" }}
                            isHoverable
                            isPressable
                            onPress={(e) =>
                              history.push(`/blogPosts/${post.slug}`)
                            }
                          >
                            <Card.Header
                              css={{ position: "absolute", zIndex: 1, top: 5 }}
                              className="overlay"
                            >
                              <Col>
                                <Text
                                  size={"0.8rem"}
                                  weight="medium"
                                  className="text-muted"
                                >
                                  Published on{" "}
                                  {dayjs(post.created_on).format(
                                    "MMMM DD, YYYY"
                                  )}
                                </Text>
                                <Text h2 color="black" transform="capitalize">
                                  {post.title}
                                </Text>
                              </Col>
                            </Card.Header>
                            <Card.Image
                              loading="lazy"
                              src={post.backdrop}
                              width="100%"
                              objectFit="cover"
                              alt={post.title}
                              css={{ aspectRatio: 1 / 1 }}
                            />
                          </Card>
                        </Grid>
                      );
                    })
                  ) : (
                    <>
                      <Grid item xs={6} md={3}>
                        <Card
                          variant="bordered"
                          css={{ w: "100%", aspectRatio: 1 / 1 }}
                          isHoverable
                        >
                          <Skeleton
                            variant="rectangle"
                            width={"100%"}
                            height={"100%"}
                          />
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Card
                          variant="bordered"
                          css={{ w: "100%", aspectRatio: 1 / 1 }}
                          isHoverable
                        >
                          <Skeleton
                            variant="rectangle"
                            width={"100%"}
                            height={"100%"}
                          />
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Card
                          variant="bordered"
                          css={{ w: "100%", aspectRatio: 1 / 1 }}
                          isHoverable
                        >
                          <Skeleton
                            variant="rectangle"
                            width={"100%"}
                            height={"100%"}
                          />
                        </Card>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Card
                          variant="bordered"
                          css={{ w: "100%", aspectRatio: 1 / 1 }}
                          isHoverable
                        >
                          <Skeleton
                            variant="rectangle"
                            width={"100%"}
                            height={"100%"}
                          />
                        </Card>
                      </Grid>
                    </>
                  )
                ) : (
                  <>
                    <Grid item xs={6} md={3}>
                      <Card
                        variant="bordered"
                        css={{ w: "100%", aspectRatio: 1 / 1 }}
                        isHoverable
                      >
                        <Skeleton
                          variant="rectangle"
                          width={"100%"}
                          height={"100%"}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Card
                        variant="bordered"
                        css={{ w: "100%", aspectRatio: 1 / 1 }}
                        isHoverable
                      >
                        <Skeleton
                          variant="rectangle"
                          width={"100%"}
                          height={"100%"}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Card
                        variant="bordered"
                        css={{ w: "100%", aspectRatio: 1 / 1 }}
                        isHoverable
                      >
                        <Skeleton
                          variant="rectangle"
                          width={"100%"}
                          height={"100%"}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Card
                        variant="bordered"
                        css={{ w: "100%", aspectRatio: 1 / 1 }}
                        isHoverable
                      >
                        <Skeleton
                          variant="rectangle"
                          width={"100%"}
                          height={"100%"}
                        />
                      </Card>
                    </Grid>
                  </>
                )}
              </Grid>
            </Container>
            <Box
              id="contact"
              className="py-5 my-5"
              sx={{ bgcolor: "grey.200" }}
            >
              <Container>
                <Typography
                  variant="h3"
                  sx={{ fontWeight: "800" }}
                  className="text-center"
                >
                  Get in touch
                </Typography>
                <Row className="mt-3">
                  <Col xs="12" sm="12" lg="4" id="address-container">
                    <Row className="justify-content-center">
                      <Box
                        id="h-address"
                        className="change-hover justify-content-center align-content-center ms-2"
                        sx={{
                          bgcolor: "grey.300",
                          width: "5rem",
                          height: "5rem",
                          borderRadius: "50%",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          id="address-text"
                          variant="h4"
                          sx={{ color: "black" }}
                        >
                          <TbLocationFilled />
                        </Typography>
                      </Box>
                      <Typography
                        className="text-center mb-2 mt-1"
                        variant="h6"
                        sx={{ fontWeight: "700", letterSpacing: "0.1rem" }}
                      >
                        Address
                      </Typography>
                      <Typography
                        className="text-muted text-center"
                        variant="h6"
                        sx={{ fontWeight: "500", letterSpacing: "0.1rem" }}
                      >
                        Lagos State, Nigeria.
                      </Typography>
                    </Row>
                  </Col>
                  <Col xs="12" sm="12" lg="4" id="mail-container">
                    <Row className="justify-content-center">
                      <Box
                        id="h-mail"
                        className="change-hover justify-content-center align-self-center align-content-center ms-2"
                        sx={{
                          bgcolor: "grey.300",
                          width: "5rem",
                          height: "5rem",
                          borderRadius: "50%",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          id="mail-text"
                          variant="h4"
                          sx={{ color: "black" }}
                        >
                          <MdEmail />
                        </Typography>
                      </Box>
                      <Typography
                        className="text-center mb-2 mt-1"
                        variant="h6"
                        sx={{ fontWeight: "700", letterSpacing: "0.1rem" }}
                      >
                        Email
                      </Typography>
                      <Typography
                        className="text-muted text-center"
                        variant="h6"
                        sx={{ fontWeight: "500", letterSpacing: "0.1rem" }}
                      >
                        <a
                          className="contact-link"
                          href="mailto:emmanueltobilola@gmail.com"
                        >
                          emmanueltobilola@gmail.com
                        </a>
                      </Typography>
                    </Row>
                  </Col>
                  <Col xs="12" sm="12" lg="4" id="phone-container">
                    <Row className="justify-content-center">
                      <Box
                        id="h-phone"
                        className="change-hover justify-content-center align-content-center ms-2"
                        sx={{
                          bgcolor: "grey.300",
                          width: "5rem",
                          height: "5rem",
                          borderRadius: "50%",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          id="phone-text"
                          variant="h4"
                          sx={{ color: "black" }}
                        >
                          <MdPhone />
                        </Typography>
                      </Box>
                      <Typography
                        className="text-center mb-2 mt-1"
                        variant="h6"
                        sx={{ fontWeight: "700", letterSpacing: "0.1rem" }}
                      >
                        Phone
                      </Typography>
                      <Typography
                        className="text-center text-muted"
                        variant="h6"
                        sx={{ fontWeight: "500", letterSpacing: "0.1rem" }}
                      >
                        <a className="contact-link" href="tel:+2349012107566">
                          +2349012107566
                        </a>
                      </Typography>
                    </Row>
                  </Col>
                </Row>
                <form className="mt-5">
                  <Row className="mt-5">
                    <Col
                      xs="12"
                      sm="6"
                      className="d-flex flex-column justify-content-between"
                    >
                      <TextField
                        required
                        fullWidth
                        label="Full name"
                        variant="standard"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <TextField
                        className="email-mt field-mt"
                        required
                        fullWidth
                        label="Email Address"
                        variant="standard"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                    <Col xs="12" sm="6">
                      <TextField
                        required
                        fullWidth
                        multiline
                        className="field-mt"
                        label="Message"
                        variant="standard"
                        rows={7}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </Col>
                    <Col className="mt-3"></Col>
                    <Stack
                      className="mt-5 j-center"
                      direction={"row"}
                      spacing={2}
                    >
                      <Button
                        variant="contained"
                        className="cv-button"
                        color="primary"
                        onClick={sendMail}
                        disabled={loading}
                      >
                        {loading ? <Loading type="gradient" /> : "SEND MESSAGE"}
                      </Button>
                    </Stack>
                  </Row>
                </form>
              </Container>
            </Box>
          </Container>
          <Footer />
        </>
      ) : (
        <div>
          <div className="d-flex vh-100 w-100 flex-column align-items-center justify-content-center">
            <Typography
              variant="h1"
              sx={{ zIndex: 90 }}
              className="position-absolute"
            >
              ET
            </Typography>
            <Loading
              type="gradient"
              loadingCss={{ $$loadingSize: "200px", $$loadingBorder: "10px" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
