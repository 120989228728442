import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import NavBar from '../components/blogNav';

import AuthContext from "../context/AuthContext";

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Card as MCard } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { Card, Input, Spacer, Loading } from "@nextui-org/react";

import { TbPassword, TbUser } from "react-icons/tb";
import Footer from "../components/footer.js";


const Register = () => {

    const history = useHistory();
    const { registerUser } = useContext(AuthContext);

    const [username, setUsername] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        registerUser(username, email, first_name, last_name, password, password2, setLoading);
    }

    return (
        <>
            <NavBar />
            <div className='mt-5 d-flex flex-column align-items-center justify-content-center'>
                <Card className="w-50 card-width">
                    <Card.Body>
                        <Typography className="mt-3" variant="h2" sx={{ textAlign: 'center', fontWeight: 700 }}>
                            Create account
                        </Typography>
                        <MCard sx={{ backgroundColor: '#000', width: "12%", maxWidth: "12%", aspectRatio: "1/1" }}
                            className="d-flex align-self-center align-items-center justify-content-center">
                            <Typography color="#fff" fontWeight={900} fontSize="2.5rem" className="et-signage">
                                ET
                            </Typography>
                        </MCard>
                        <Spacer y={5} />
                        <Grid container spacing={2} autoComplete="new-password">
                            <Grid item xs={12} sm={6} md={6}>
                                <Input
                                    clearable
                                    underlined
                                    size="lg"
                                    required
                                    fullWidth
                                    labelPlaceholder="First name"
                                    autoComplete="new-password"
                                    contentLeft={
                                        <TbUser />
                                    }
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                <Spacer y={2.5} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Input
                                    clearable
                                    underlined
                                    size="lg"
                                    required
                                    fullWidth
                                    labelPlaceholder="Last name"
                                    autoComplete="new-password"
                                    contentLeft={
                                        <TbUser />
                                    }
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                                <Spacer y={2.5} />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <Input
                                    clearable
                                    underlined
                                    size="lg"
                                    required
                                    fullWidth
                                    labelPlaceholder="Username"
                                    autoComplete="new-password"
                                    contentLeft={
                                        <TbUser />
                                    }
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                <Spacer y={2.5} />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <Input
                                    clearable
                                    underlined
                                    size="lg"
                                    required
                                    fullWidth
                                    labelPlaceholder="Email address"
                                    type="email"
                                    autoComplete="new-password"
                                    contentLeft={
                                        <TbUser />
                                    }
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Spacer y={2.5} />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                            <Input.Password
                                underlined
                                fullWidth
                                clearable
                                labelPlaceholder="Password"
                                autoComplete="new-password"
                                contentLeft={
                                    <TbPassword />
                                }
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Spacer y={2.5} />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                            <Input.Password
                                underlined
                                fullWidth
                                clearable
                                labelPlaceholder="Password again"
                                autoComplete="new-password"
                                contentLeft={
                                    <TbPassword />
                                }
                                onChange={(e) => setPassword2(e.target.value)}
                            />
                            </Grid>
                            <div className="container w-75 mt-5 align-self-center d-grid">
                                <Button variant="contained" className="cv-button mt-5"
                                    color="primary" onClick={handleSubmit} disabled={loading}>
                                    {loading ? <Loading /> : "CREATE ACCOUNT"}
                                </Button>
                            </div>
                            <Spacer y={8} />
                        </Grid>
                            <Typography variant="h5" textAlign='center' className="d-flex justify-content-center logIn-signUp">
                                Don't have an account?&nbsp;&nbsp;&nbsp;
                                <Link underline='none'
                                    component='button' variant="h5"
                                    onClick={(e) => history.push('/blogPosts/account/Login')}>
                                    Login
                                </Link>
                            </Typography>
                    </Card.Body>
                </Card>
            </div>
            <Footer />
        </>
    )
}

export default Register;