import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useAxios from "../utils/useAxios";
import AuthContext from "../context/AuthContext";

import NavBar from '../components/blogNav';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { Loading } from '@nextui-org/react';

import {
    Container,
    Row,
    Col,
} from "reactstrap";

import { toast } from 'react-toastify';
import Footer from "../components/footer.js";



const NewProject = () => {

    const api = useAxios();
    const { user } = useContext(AuthContext);

    const history = useHistory();
    const location = useLocation();

    const [title, setTitle] = useState('');
    const [backdrop, setBackdrop] = useState(null);
    const [link, setLink] = useState('');

    const [loading, setLoading] = useState(false);


    const titleChange = (e) => {
        setTitle(e.target.value);
    }

    const linkChange = (e) => {
        setLink(e.target.value);
    }

    const backdropChange = (e) => {
        setBackdrop(e.target.files[0])
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        setLoading(true);

        if (location.state) {
            if (location.state.title) {
                setTitle(location.state.title);
            }
            if (location.state.link) {
                setLink(location.state.link);
            }
        }

        let form_data = new FormData();

        if (backdrop) {
            form_data.append('backdrop', backdrop, backdrop.name);
        }
        form_data.append('title', title);
        form_data.append('link', link);

        api.patch(`${process.env.REACT_APP_API_URL}/blog-posts/${location.state.slug}/update`, form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => {
                setLoading(false);
                history.push(`/blogPosts/${res.data.slug}`);
                toast.success("Post updated successfuly");
            })
            .catch(err => {
                if (err.response.data.hasOwnProperty("detail")) {
                    toast.error(err.response.data['detail'].toString())
                }
                if (err.response.data.hasOwnProperty("title")) {
                    toast.error(`Title Error: ${err.response.data['title'].toString()}`)
                }
                if (err.response.data.hasOwnProperty("backdrop")) {
                    toast.error(`Backdrop Error: ${err.response.data['backdrop'].toString()}`)
                }
                if (err.response.data.hasOwnProperty("body")) {
                    toast.error(`Body Error: ${err.response.data['body'].toString()}`)
                }
                setLoading(false)
            })
    }

    const handleCreate = (e) => {
        e.preventDefault();
        setLoading(true);

        let form_data = new FormData();

        if (backdrop) {
            form_data.append('backdrop', backdrop, backdrop.name);
        }
        form_data.append('title', title);
        form_data.append('link', link);

        api.post(`${process.env.REACT_APP_API_URL}/blog-posts/project/new`, form_data, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        })
            .then(res => {
                setLoading(false);
                history.push(`/`);
                toast.success("Projet added successfuly");
            })
            .catch(err => {
                if (err.response.data.hasOwnProperty("detail")) {
                    toast.error(err.response.data['detail'].toString())
                }
                if (err.response.data.hasOwnProperty("title")) {
                    toast.error(`Title Error: ${err.response.data['title'].toString()}`)
                }
                if (err.response.data.hasOwnProperty("backdrop")) {
                    toast.error(`Backdrop Error: ${err.response.data['backdrop'].toString()}`)
                }
                if (err.response.data.hasOwnProperty("link")) {
                    toast.error(`Link Error: ${err.response.data['link'].toString()}`)
                }
                setLoading(false)
            })
    };


    return (
        <>
            <NavBar />
            <Container className="mt-5">
                <Col>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        {location.state ? `Edit project` : 'Add new Project'}
                    </Typography>
                    <Divider className='mb-3' sx={{
                        opacity: '0.8!important', background: '#000',
                        height: '0.1rem!important'
                    }} />
                    
                    <Row className="mt-3">
                        {location.state ?
                            <TextField fullWidth value={location.state.title} label="Title" variant="outlined"
                                onChange={titleChange} />
                        : 
                             <TextField fullWidth label="Title" variant="outlined"
                        onChange={titleChange} />
                         }
                    </Row>
                    <Row className="mt-3">
                        {location.state ?
                            <TextField fullWidth type="url" value={location.state.link} label="Link" variant="outlined"
                                onChange={linkChange} />
                            :
                            <TextField fullWidth type="url" label="Link" variant="outlined"
                                onChange={linkChange} />
                        }
                    </Row>
                    <Row className="my-3">
                        <TextField fullWidth 
                            label="Backdrop" variant="outlined" type="file"
                            onChange={backdropChange} accept="image/*" />
                    </Row>
                    {user ? (
                        <Stack className="j-center w-100" direction={'row'} spacing={2}>
                            <Button disabled={loading} onClick={location.state ? handleUpdate : handleCreate} variant="contained"
                                className="cv-button mt-3" color="primary">
                                {loading ? <Loading type="gradient" /> : 'Add project'}
                            </Button>
                        </Stack>
                    ) : (<></>)}
                    
                </Col>
            </Container>
            <Footer />
        </>
    )
}

export default NewProject;